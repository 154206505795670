@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Helvetica Neue";
    font-weight: 200;
    src: url("../fonts/helvetica-neue/HelveticaNeue-Thin.otf");
}

@font-face {
    font-family: "Helvetica Neue";
    font-weight: 300;
    src: url("../fonts/helvetica-neue/HelveticaNeue-Light.otf");
}

@font-face {
    font-family: "Helvetica Neue";
    font-weight: 400;
    src: url("../fonts/helvetica-neue/HelveticaNeue-Roman.otf");
}

@font-face {
    font-family: "Helvetica Neue";
    font-weight: 500;
    src: url("../fonts/helvetica-neue/HelveticaNeue-Medium.otf");
}

@font-face {
    font-family: "Helvetica Neue";
    font-weight: 700;
    src: url("../fonts/helvetica-neue/HelveticaNeueBold.ttf");
}